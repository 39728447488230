import keyMirror from 'keymirror';

export default keyMirror({
  SECTION_TITLE_REND: null,
  FORM_DESC_REND: null,
  CONFIRM_TEXT_REND: null,
  CONFIRM_DESC_REND: null,
  SUBSCRIBE_LABEL_REND: null,
  PLACEHOLDER_TEXT_REND: null,
  VERIFICATION_TEXT_REND: null,
  SUBSCRIBE_INNER_FORM_REND: null,
  SUBSCRIBE_EMAIL_ERR_REND: null,
  SUBSCRIBE_SUBMIT_BUTTON_REND: null,
  SECTION_BACKGROUND: null,
  COUPON_DESC_REND: null,
  COUPON_CODE_REND: null,
  COUPON_CODE_FETCHING: null
});
